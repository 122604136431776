import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public checkUnique(username) {
    return this.http.get(`${environment.api}/user/${username}`);
  }

  public createUser(user) {
    return this.http.post(`${environment.api}/user/create`, user);
  }
}
