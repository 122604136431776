<div class="modal-header">
  <h4 class="modal-title">Use Your Own Charity/Foundation</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="mt-4">
    <div id="byoCharity" class="byo-charity">
      <form class="form-group" [formGroup]="charityForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Foundation Name: <span>*</span></label>
          <input type="text" id="foundation_name" name="foundation_name" placeholder="Foundation Name" formControlName="name" class="form-control">
        </div>
        <div class="form-group">
          <label>Foundation EIN#: <span>*</span></label>
          <input type="text" id="foundation_ein" name="foundation_ein" placeholder="Foundation EIN#" formControlName="ein" class="form-control">
        </div>


        <div class="form-group">
          <label>Address 1: <span>*</span></label>
          <input type="text" name="address_1" placeholder="Address 1" formControlName="street" class="form-control">
        </div>
        <div class="form-group">
          <label>City: <span>*</span></label>
          <input type="text" name="city" placeholder="City" formControlName="city" class="form-control">
        </div>

        <div class="row">
          <div class="form-group col">
            <label>State: <span>*</span></label>
            <input type="text" name="state" placeholder="State" formControlName="state" class="form-control">
          </div>
          <div class="form-group col">
            <label>Zip Code: <span>*</span></label>
            <input type="text" name="zipcode" placeholder="Zip Code" formControlName="zip" class="form-control">
          </div>
        </div>
        <div class="form-group col">
          <label>Country: <span>*</span></label>

          <ng-select #countrySelect [items]="countries"
            [selectOnTab]="true"
            bindLabel="country"
            bindValue="abbreviation"
            labelForId="Country"
            placeholder="Select a Country"
            formControlName="country">
          </ng-select>
        </div>
        <div class="form-group">
          <label>Description: </label>
          <input type="text" id="foundation_description" name="foundation_description" placeholder="Foundation Description" formControlName="short_description" class="form-control">
        </div>
        <div class="form-group col">
          <label>Logo: <span>*</span></label>
          <input type="file" name="logo" accept="image/png; image/jpeg" (change)="onChange($event)" class="form-control">
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  <button type="submit"  class="btn btn-primary" [disabled]="!charityForm.valid" *ngIf="!loading" (click)="onSubmit()">Submit</button>
  <button class="btn btn-primary" type="button" disabled *ngIf="loading">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Loading...
  </button>
</div>
