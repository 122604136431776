import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CharityService } from '../../services/charity.service';
import  *  as  Countries  from  './country-by-abbreviation.json';

@Component({
  selector: 'app-new-charity',
  templateUrl: './new-charity.component.html',
  styleUrls: ['./new-charity.component.css']
})
export class NewCharityComponent implements OnInit {
  @Input() charities;
  newCharity = false;
  charityForm;
  isSubmitted = false;
  countries: any;
  loading = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private charityService: CharityService) {}

  ngOnInit(): void {
    this.countries = (Countries as any).default;

    this.charityForm = this.fb.group({
      name: ['', Validators.required],
      ein: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      short_description: [''],
      country: [null, [Validators.required]],
      logo: ['', Validators.required]
    });
  }

  onChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.charityForm.get('logo').setValue(file);
    }
  }

  onSubmit() {
    if (!this.charityForm.valid) {
      return false;
    }

    let copy = { ... this.charityForm.value };
    delete copy.logo;

    const formData = new FormData();
    formData.append('logo', this.charityForm.get('logo').value);
    this.loading = true;
    this.charityService.createCharity(copy).subscribe((result: any) => {
      this.charityService.updateCharityLogo(result.id, formData).subscribe((charity: any) => {
        this.charities.push(JSON.parse(charity));
        this.isSubmitted = true;
        this.loading = false;
        this.activeModal.close(JSON.parse(charity));
      }, err => {
        this.loading = false;
        console.error(err);
      });
    }, err => {
      this.loading = false;
      console.error(err);
    });
  }

}
