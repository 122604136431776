<div class="form-wizard form-header-classic form-body-classic" *ngIf="!isSubmitted">
    <!-- Form progress -->
    <div class="form-wizard-steps form-wizard-tolal-steps-4">
      <div class="form-wizard-progress">
          <div class="form-wizard-progress-line" [ngStyle]="{'width': ((100 / maxSteps) * step) - 12 + '%'}"></div>
      </div>
<!-- Step 1 -->
      <div class="form-wizard-step" [ngClass]="{'active': step === 1}">
        <div class="form-wizard-step-icon"><i class="fa fa-unlock-alt" aria-hidden="true"></i></div>
        <p>Account</p>
      </div>
<!-- Step 1 -->

<!-- Step 2 -->
      <div class="form-wizard-step" [ngClass]="{'active': step === 2}">
        <div class="form-wizard-step-icon"><i class="fa fa-user" aria-hidden="true"></i></div>
        <p>Select Charity</p>
      </div>
<!-- Step 2 -->

<!-- Step 3 -->
        <div class="form-wizard-step" [ngClass]="{'active': step === 3}">
        <div class="form-wizard-step-icon"><i class="fa fa-university" aria-hidden="true"></i></div>
        <p>Payment Info</p>
      </div>
<!-- Step 3 -->

<!-- Step 4 -->
<div class="form-wizard-step" [ngClass]="{'active': step === 4}">
        <div class="form-wizard-step-icon"><i class="fa fa-file-text" aria-hidden="true"></i></div>
        <p>Confirm</p>
      </div>
<!-- Step 4 -->
<div class="form-progress">
  <p><ngb-progressbar [value]="(100 / maxSteps) * step" [striped]="true" [animated]="true"><i></i></ngb-progressbar></p>
</div>
    </div>


    <form class="form-group" [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

      <div class="form-step-1" *ngIf="step === 1">
        <div class="row">
          <h4 class="form-group ">Account Information: <span>Step 1 - 4</span></h4>

        </div>
        <div class="row">

          <div class="form-group col">
            <label for="first-name">First Name: <span>*</span></label>
            <input id="first-name" class="form-control" placeholder="First Name" type="text" formControlName="first_name" required>
          </div>

          <div class="form-group col">
            <label for="last-name">Last Name: <span>*</span></label>
            <input id="last-name" class="form-control" placeholder="Last Name" type="text" formControlName="last_name" required>
          </div>
        </div>


        <div class="form-group">
          <label>Username: <span>*</span></label>
          <input type="username"
            [ngClass]="{'error': isUnique === false &&  (registrationForm.controls['username'].dirty || registrationForm.controls['username'].touched), 'success': isUnique &&  (registrationForm.controls['username'].dirty || registrationForm.controls['username'].touched)}" name="username" placeholder="Username" class="form-control" formControlName="username" (change)="changeUsername($event)" required>
          <div *ngIf="registrationForm.controls['username'].invalid && (registrationForm.controls['username'].dirty || registrationForm.controls['username'].touched)" class="alert">
            <div *ngIf="registrationForm.controls['username'].errors.required">
              Please enter username.
            </div>
            <div *ngIf="registrationForm.controls['username'].errors.whitespace">
              Usernames cannot contain whitespace.
            </div>
            <div *ngIf="registrationForm.controls['username'].errors.notUnique">
              Usernames must be unique.
            </div>
        </div>
        </div>
        <div class="form-group">
          <label>Email: <span>*</span></label>
          <input type="email" name="email" placeholder="Email" class="form-control" formControlName="email" required>
        </div>
        <div class="form-group">
          <label>Phone: <span>*</span></label>
          <input
          [ngClass]="{'error': isUnique === false &&  (registrationForm.controls['phone'].dirty || registrationForm.controls['phone'].touched), 'success': (registrationForm.controls['phone'].dirty || registrationForm.controls['phone'].touched)}"
          type="phone" name="phone" placeholder="Phone" class="form-control" formControlName="phone" required>
          <div *ngIf="registrationForm.controls['phone'].invalid && (registrationForm.controls['phone'].dirty || registrationForm.controls['phone'].touched)" class="alert">
            <div *ngIf="registrationForm.controls['phone'].errors.required">
              Please enter a phone number.
            </div>
            <div *ngIf="registrationForm.controls['phone'].errors.wrongNumber">
              Phone number is not in the correct format. E.g: +17035555555.
            </div>
        </div>
        </div>
        <div class="form-group">
          <label>Your Bio: <span>*</span></label>
          <textarea type="short description" name="short description" placeholder="Your Bio..." class="form-control" formControlName="bio" rows="2" required></textarea>
        </div>
        <div class="form-group">
          <label>Title: <span>*</span></label>
          <input type="title" name="title" placeholder="e.g. NFL Superbowl Champion" class="form-control" formControlName="title" required>
        </div>
        <div class="form-group">
          <label>Who Referred you?</label>
          <input type="referred_by" name="referred_by" placeholder='If nobody referred you, indicate "self"' class="form-control" formControlName="referred_by">
        </div>
        <!--<div class="form-group">
          <label>Category: <span>*</span></label>
          <ng-select [items]="categories"
            formControlName="category_id"
            bindLabel="name"
            bindValue="id"
            groupBy="children"
            [multiple]="false"
            placeholder="Select a Category"
            [(ngModel)]="category_id">
            <ng-template ng-optgroup-tmp let-item="item">
                {{item.name}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{item.name}}
            </ng-template>
          </ng-select>
        </div>-->
      </div>

      <div class="form-step-2" *ngIf="step === 2">
        <div class="form-group mt-4">
          <h4>Select a default Charity to support <span>Step 2 - 4</span></h4>

          <label>Charity: </label><br>
          <div class="form-group">
            <div class="custom-image-select" >
              <div class="row mt-2 charity-items">
                <ng-container *ngFor="let charity of charities; let i = index">
                  <div class="clearfix" *ngIf="i % 5 === 0"></div>
                  <label class="radio col"
                    [ngValue]="charity"
                    (click)="changeCharity(charity)"
                    [ngClass]="{'selected': charityId.value === charity.id}">
                    <img src="{{charity.logo}}" alt="{{charity.name}}" >
                    <input type="radio" name="Charity" value="{{charity.id}}">
                  </label>
                </ng-container>

              </div>
            </div>
          </div>
          <br>
          <p><button id="newCharity" (click)="openNewCharity(charities)" type="button" class="btn btn-next">+ Add Your Own Charity</button></p>
          <p class="spacer">- or -</p>
          <div>
            <input type="checkbox" class="no-charity-checkbox" [ngValue]="currentCharity" (click)="charityId.value = null; currentCharity = null" />
            <label>No Charity at this time.</label>
          </div>
        </div>
      </div>

      <div class="form-step-3" *ngIf="step === 3">
        <div class="mt-4">
          <h4>How would you like to be paid? <span>Step 3 - 4</span></h4>

          <small>Payouts are processed monthly. </small>
          <div class="row">
            <div class="col pt-4">
              <img src="/assets/images/PayPal-Logo.png" alt="Paypal">
            </div>

             <div class="form-group col-10">
               <label>Paypal Address <span>*</span></label>
               <input type="email" name="Paypal Address" placeholder="Paypal Address" class="form-control" formControlName="paypal_email" required>
             </div>
          </div>
        </div>
      </div>

      <div class="form-step-4" *ngIf="step === 4">
        <h4 class="mt-4">Confirm Details: <span>Step 4 - 4</span></h4>

        <table class="table">
          <tr><th>First Name</th><td id="firstNameDisplay">{{ registrationForm.value.first_name }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.first_name"><td colspan="2"><ng-container *ngFor="let err of errors.error.first_name">{{ err }}</ng-container></td></tr>
          <tr><th>Last Name</th><td id="lastNameDisplay">{{ registrationForm.value.last_name }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.last_name"><td colspan="2"><ng-container *ngFor="let err of errors.error.last_name">{{ err }}</ng-container></td></tr>
          <tr><th>Username</th><td id="usernameDisplay">{{ registrationForm.value.username }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.username"><td colspan="2"><ng-container *ngFor="let err of errors.error.username">{{ err }}</ng-container></td></tr>
          <tr><th>Email</th><td id="emailDisplay">{{ registrationForm.value.email }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.email"><td colspan="2"><ng-container *ngFor="let err of errors.error.email">{{ err }}</ng-container></td></tr>
          <tr><th>Phone</th><td id="emailDisplay">{{ registrationForm.value.phone }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.phone"><td colspan="2"><ng-container *ngFor="let err of errors.error.phone">{{ err }}</ng-container></td></tr>
          <tr><th>Description</th><td id="descriptionDisplay">{{ registrationForm.value.bio }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.bio"><td colspan="2"><ng-container *ngFor="let err of errors.error.bio">{{ err }}</ng-container></td></tr>
          <tr><th>Title</th><td id="titleDisplay">{{ registrationForm.value.title }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.title"><td colspan="2"><ng-container *ngFor="let err of errors.error.title">{{ err }}</ng-container></td></tr>
          <tr><th>Referred By</th><td id="referredByDisplay">{{ registrationForm.value.referred_by }}</td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.referred_by"><td colspan="2"><ng-container *ngFor="let err of errors.error.referred_by">{{ err }}</ng-container></td></tr>

          <!--<tr><th>Category</th><td id="categoryDisplay"><ng-container *ngIf="registrationForm.value.category_id">{{ currentCategory(registrationForm.value.category_id) }}</ng-container></td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.category_id"><td colspan="2"><ng-container *ngFor="let err of errors.error.category_id">{{ err }}</ng-container></td></tr>-->
          <tr class="charityDisplay"><th>Charity</th><td id="charityDisplay"><ng-container *ngIf="currentCharity && currentCharity.name">{{ currentCharity.name }}</ng-container></td></tr>
          <tr class="table-danger" *ngIf="errors && errors.error && errors.error.charity_id"><td colspan="2"><ng-container *ngFor="let err of errors.error.charity_id">{{ err }}</ng-container></td></tr>

          <ng-container *ngIf="customFoundation">
            <tr class="foundationDisplay"><th>Foundation Name</th><td id="foundationNameDisplay">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation EIN#</th><td id="foundationEINDisplay">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation Address 1</th><td id="foundationAddressDisplay">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation Address 2</th><td id="foundationAddress2Display">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation City</th><td id="foundationCityDisplay">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation State</th><td id="foundationStateDisplay">Populate Info Here</td></tr>
            <tr class="foundationDisplay"><th>Foundation Zipcode</th><td id="foundationZipDisplay">Populate Info Here</td></tr>
          </ng-container>

          <tr><th>Paypal Address</th><td id="paypalAddressDisplay">{{ registrationForm.value.paypal_email }}</td></tr>
          <tr *ngIf="errors && errors.error && errors.error.paypal_email"><th>Error</th><td><ng-container *ngFor="let err of errors.error.paypal_email">{{ err }}</ng-container></td></tr>

          </table>
          <br>
          <div class="row">
          <h4>Accept Terms & Conditions</h4>
          <p>
            By submitting I agree to the <a href="terms.html" target="_blank">Terms of Service</a> & <a href="/privacy_policy.html" target="_blank">Privacy Policy</a>
          </p>
          </div>

      </div>

      <div class="mt-4 form-wizard-buttons">
        <button type="button" class="btn btn-previous" (click)="changeStep()" *ngIf="step > 1">Previous</button>
        <button type="button" class="btn btn-next" (click)="changeStep(true, stepInvalid(step))" [disabled]="stepInvalid(step)" *ngIf="step !== maxSteps">Next</button>
        <button type="submit"  class="btn btn-submit" [disabled]="!registrationForm.valid" *ngIf="step === maxSteps && !loading">Submit</button>
        <button class="btn btn-submit" type="button" disabled *ngIf="loading">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    </form>
</div>

<div *ngIf="isSubmitted" class="submitted">
  <h3>Congratulations Your Profile Has Been Setup</h3>
  <p>You have just been sent a welcome email with your Invite Code. You will need this code to activate your Star Shoutout Studio Mobile App. You will also see the Invite Code here below. Download the app, click on the Setup Your Account Button and enter your Invite Code.</p>
  <p>Your invite code is:</p>
  <h1 class="authcode"><b>{{ authCode}}</b></h1>

  <p>Download the <b>Star Shoutout</b> app and put in this Invite Code to get started.</p>
  <div class="success-buttons">
    <div class="row align-items-center">
      <a class="col" href="https://apps.apple.com/us/app/star-shoutout-studio/id1579765362" target="_blank"><img class="appstore-button" src="assets/images/ios-download.png"></a>
      <a class="col" href="https://play.google.com/store/apps/details?id=com.starshoutout.studio" target="_blank"><img class="appstore-button" src="assets/images/android-download.png"></a>
    </div>
  </div>
</div>
