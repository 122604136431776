<!-- Split Section -->
<section class="split-wrapper">
  <div class="split-container">
      <div id="intro" class="split-col col-left" *ngIf="!registration">
          <div class="content">
              <div class="logo-container d-none">
                  <img src="/images/icons/vip_3_small.png" height="100" width="100" alt="VIP Star Registration"/>
              </div>
              <h1 class=" d-none">VIP Star Registration</h1>
              <p class=" d-none">Authorized Persons Only.</p>
              <div class="dl-wrapper">
                  <button id="register" (click)="registration = true" type="button" class="btn btn-next">Register</button>
      <button (click)="showDeck = true" id="deck" type="button" class="btn btn-next">How It Works</button>
      <button (click)="showVideo = true" type="button" class="btn btn-next">View Video</button>
              </div>
          </div>
      </div>


                   <!-- main content -->
  <section id="section-registration" class="split-col col-left" *ngIf="registration">
      <div class="content">

          <div class="row ">
    <!-- col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-2 -->
    <div>
                  <div class="logo-container d-none">
                      <img src="/images/icons/vip_3_small.png"  height="100" width="100" alt="VIP Star Registration"/>
                  </div>
                  <h1 class=" d-none">VIP Star Registration</h1>
                  <p class=" d-none">Authorized Persons Only.</p>
                  <app-enrollment-form [categories]="categories" [charities]="charities"></app-enrollment-form>
              </div>
          </div>

      </div>
  </section>
<!-- main content -->
  <div id="phone-demo" class="split-col col-right">
      <div class="content ios-mockup drop-shadow">
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="phone-image"></div>
      <iframe src="https://player.vimeo.com/video/556273690?background=1" width="245" height="531" frameborder="0" autoplay="true" loop="true" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
  <!--
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="/images/cinematic/screen-1.png" alt="app coming soon"/>
        </div>
        <div class="swiper-slide">
          <img src="/images/cinematic/screen-2.png" alt="app landing page"/>
        </div>
        <div class="swiper-slide">
          <img src="/images/cinematic/screen-3.png" alt="app showcase"/>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  -->
      </div>
  </div>
</div>
</section>

<div id="videoplayer" class="videoplayer" *ngIf="showVideo">
<div class="video">
<span id="closeVideo" class="close" (click)="showVideo = !showVideo"></span>
<div class="video-container">
  <div class="bg">
    <iframe width="560" height="315" src="https://player.vimeo.com/video/553840708?width=1080&amp;autoplay=0&amp;byline=0&amp;portrait=0&amp;color=cccccc" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
  </div>
</div>
</div>
</div>

<div id="deck" class="videoplayer" *ngIf="showDeck">
  <div class="video">
  <span id="closeDeck" class="close" (click)="showDeck = !showDeck"></span>
  <div class="video-container">
    <div class="bg">
      <iframe width="560" height="315" src="https://beta.starshoutout.com/benefits/" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
    </div>
  </div>
  </div>
  </div>
