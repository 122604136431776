import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl  } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewCharityComponent } from '../new-charity/new-charity.component';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
  selector: 'app-enrollment-form',
  templateUrl: './enrollment-form.component.html',
  styleUrls: ['./enrollment-form.component.css']
})
export class EnrollmentFormComponent implements OnInit {
  @Input() categories: any;
  @Input() charities: any;

  isSubmitted = false;
  loading = false;
  isUnique;
  customFoundation = false;
  step = 1;
  maxSteps = 4;
  registrationForm;
  currentCharity;
  errors;
  lastCheck;
  authCode;

  get categoryId() {
    return this.registrationForm.get('category_id');
  }

  get username() {
    return this.registrationForm.get('username');
  }

  get charityId() {
    return this.registrationForm.get('charity_id');
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const noWhitespace = (control.value.length === (control.value).trim().length);
    const isValid = !isWhitespace && noWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public phoneNumberUtil = PhoneNumberUtil.getInstance();

  public phoneNumberValidator = (regionCode: string = undefined): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = false;
      try {
        const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(
          control.value, regionCode
        );
        validNumber = this.phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) { }

      return validNumber ? null : { 'wrongNumber': { value: control.value } };
    }
  }

  constructor(private fb: FormBuilder, private userService: UserService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      username: ['', [Validators.required, this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, this.phoneNumberValidator('US')]],
      bio: ['', Validators.required],
      title: ['', Validators.required],
      category_id: [null],
      referred_by: [''],
      paypal_email: ['', [Validators.required, Validators.email]],
      charity_id: [null]
    });

    this.onChanges();
  }

  onChanges(): void {
    this.registrationForm.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.errors = null;
        if (val.username.length > 0 && val.username !== this.lastCheck) {
          this.userService.checkUnique(val.username)
          .subscribe((result:any) => {
            this.lastCheck = val.username;
            if (result.isUnique && result.isUnique === true) {
              return this.isUnique = true;
            } else {
              this.registrationForm.controls['username'].setErrors({'notUnique': true});
              return this.isUnique = false;
            }
          });
        }
      });
  }

  changeCharity(e): void {
    this.currentCharity = e;
    this.charityId.setValue(e.id, {
      onlySelf: true
    })
  }

  changeUsername(e): void {
    this.username.setValue(e.target.value, {
      onlySelf: true
    })
  }

  currentCategory(id) {
    if (!id || !this.categories) {
      return;
    }
    let category;

    this.categories.filter(cat => {
      if (cat.id === id) {
        category = cat.name;
      } else if (cat.children.length > 0) {
        cat.children.filter(child => {
          if (child.id === id) {
            category = child.name;
          }
        })
      }
    });
    return category;
  }

  onSubmit() {
    if (!this.registrationForm.valid) {
      return false;
    } else {
      this.errors = null;
      this.loading = true;
      this.registrationForm.value.phone = this.phoneNumberUtil.format(this.phoneNumberUtil.parse(this.registrationForm.value.phone, 'US'), PhoneNumberFormat.E164);
      this.userService.createUser(this.registrationForm.value).subscribe((user: any) => {
        this.isSubmitted = true;
        this.loading = false;
        this.authCode = user.authorization_code;
      }, (err) => {
        this.loading = false;
        this.errors = err;
      });
    }
  }

  changeStep(increment, invalid) {
    if (invalid) {
      return;
    }
    if (increment && this.step + 1 <= this.maxSteps) {
      this.step++;
    } else if (this.step - 1 >= 0) {
      this.step--;
    }
  }

  stepInvalid(step) {
    switch (step) {
      case 1:
        if (
          this.registrationForm.get('first_name').valid &&
          this.registrationForm.get('last_name').valid &&
          this.registrationForm.get('username').valid &&
          this.registrationForm.get('email').valid &&
          this.registrationForm.get('phone').valid &&
          this.registrationForm.get('bio').valid &&
          //this.registrationForm.get('category_id').valid &&
          this.registrationForm.get('title').valid ){
            return false;
          }
          return true;
      case 2:
        return false
      case 3:
        if (
          this.registrationForm.get('paypal_email').valid) {
            return false;
          }
          return true;
      default:
          return true;
    }
  }

  openNewCharity(charities) {
    const modalRef = this.modalService.open(NewCharityComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.charities = charities;
    modalRef.result.then(charity => {
      this.changeCharity(charity);
    }, err => {
      console.log(err);
    })
  }

}
