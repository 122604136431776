import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CharityService {

  constructor(private http: HttpClient) { }

  public getCharities() {
    return this.http.get(`${environment.api}/charity/all?page_size=100`);
  }

  public createCharity(charity) {
    return this.http.post(`${environment.api}/charity/`, charity);
  }

  public partialUpdateCharity(id, charity) {
    return this.http.patch(`${environment.api}/charity/${id}/`, charity);
  }

  public updateCharityLogo(id, charity) {
    return this.http.patch(`${environment.api}/charity/${id}/logo`, charity);
  }
}
