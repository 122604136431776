import { Component, OnInit, OnChanges } from '@angular/core';
import { UserService } from '../services/user.service';
import { CharityService } from '../services/charity.service';
import { CategoryService } from '../services/category.service';


@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.css']
})
export class EnrollmentComponent implements OnInit, OnChanges {
  categories;
  charities;
  showVideo = false;
  showDeck = false;
  registration = true;
  constructor(private userService: UserService,
    private charityService: CharityService,
    private cateoryService: CategoryService) { }

  ngOnInit(): void {
    this.cateoryService.getCategories()
      .subscribe(cats => {
        this.categories = cats;
      });
    this.charityService.getCharities()
      .subscribe(char => {
        this.charities = char;
      });
  }

  ngOnChanges(): void {
  }

}
